import Mopidy from "mopidy";

const host = window.location.hostname;
const port = 6680;
// const port = window.location.port
//   ? window.location.port
//   : window.location.protocol === "https:"
//   ? "443"
//   : "80";
const ssl = window.location.protocol === "https:";
// @ts-ignore
export const mopidyInstance = new Mopidy({
  webSocketUrl: `ws${ssl ? "s" : ""}://${host}:${port}/mopidy/ws/`,
});

export const play = () => {
  // @ts-ignore
  return mopidyInstance.playback?.play();
};

export const pause = () => {
  return mopidyInstance.playback?.pause();
};

export const search = async (query: string, includeYoutube: boolean) => {
  const payload: { query: Mopidy.core.Query; uris: string[] } = {
    query: query.includes("://" || "spotify:track:" || "soundcloud:")
      ? { uri: [query] }
      : { any: [query] },
    uris: includeYoutube
      ? ["spotify:", "soundcloud:", "youtube:", "tidal:"]
      : ["spotify:", "soundcloud:", "tidal:"],
  };
  if (query.includes("artist:")) {
    const q = query.split(" ");
    const qWithoutArtist = q.filter((x) => !x.includes("artist:")).join(" ");
    const artistQ = q
      .find((x) => x.includes("artist:"))!
      .replace("artist:", "");
    payload.query.any = [qWithoutArtist];
    if (artistQ) {
      payload.query.artist = [artistQ];
    }
    if (!qWithoutArtist) {
      return;
    }
  }
  return mopidyInstance.library?.search(payload);
};

export const addToQueue = async (track: Mopidy.models.Track) => {
  mopidyInstance.tracklist?.add({ tracks: [track] });
};
