






























import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Playlist",
  computed: {
    ...mapGetters(["getPlaylist", "getPlaybackState"]),
  },
});
