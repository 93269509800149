import { render, staticRenderFns } from "./CurrentSong.vue?vue&type=template&id=40271da5&scoped=true&"
import script from "./CurrentSong.vue?vue&type=script&lang=ts&"
export * from "./CurrentSong.vue?vue&type=script&lang=ts&"
import style0 from "./CurrentSong.vue?vue&type=style&index=0&lang=css&"
import style1 from "./CurrentSong.vue?vue&type=style&index=1&id=40271da5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40271da5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VAppBar,VCol,VIcon,VImg,VRow,VSkeletonLoader,VSlider,VSnackbar})
