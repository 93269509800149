














import Vue from "vue";
import { mopidyInstance } from "@/utils/mopidy";

export default Vue.extend({
  name: "ProgressionBlock",
  props: ["songLength", "playing"],
  data: () => {
    return {
      elapsed: 0,
      intervalId1: 0,
      intervalId2: 0,
    };
  },
  methods: {
    formatTime(timeStamp: number) {
      const songLength = new Date(timeStamp);
      return `${songLength.getMinutes()}:${songLength
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
    },
    startTimer() {
      this.intervalId1 = setInterval(
        () => (this.elapsed = this.elapsed + 1000),
        1000
      );
    },
    async startTrackTimePolling() {
      const currentTime = await mopidyInstance.playback?.getTimePosition();
      if (typeof currentTime === "number") {
        this.elapsed = currentTime;
      }
      this.intervalId2 = setInterval(async () => {
        const currentTime = await mopidyInstance.playback?.getTimePosition();
        if (typeof currentTime === "number") {
          this.elapsed = currentTime;
        }
      }, 5000);
    },
  },
  computed: {
    songElapsedPercentage() {
      return Math.round((this.elapsed / this.songLength) * 100);
    },
  },
  watch: {
    playing(newVal) {
      if (newVal === "playing") {
        this.startTimer();
        this.startTrackTimePolling();
      } else {
        clearInterval(this.intervalId1);
        clearInterval(this.intervalId2);
      }
    },
    songLength() {
      this.elapsed = 0;
    },
  },
  mounted() {
    if (this.playing === "playing") {
      this.startTimer();
    }
    this.startTrackTimePolling();
  },
});
