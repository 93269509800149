




























import Vue from "vue";
import CurrentSong from "@/components/CurrentSong/CurrentSong.vue";
import Search from "@/components/Search/Search.vue";
import Playlist from "@/components/Playlist/Playlist.vue";
import { mopidyInstance } from "./utils/mopidy";

export default Vue.extend({
  name: "App",
  created: async function () {
    mopidyInstance.on("state:online", async () => {
      this.$store.dispatch("getCurrentTrackList");
      this.$store.dispatch("getPlaybackState");
      this.$store.dispatch("getCurrentTrack");
      mopidyInstance.tracklist?.setConsume({ value: true });
      const volume = await mopidyInstance.mixer?.getVolume();
      this.$store.commit('setVolume', volume);
    });
    mopidyInstance.on("event:volumeChanged", (event) => {
      this.$store.commit('setVolume', event.volume);
    })
    mopidyInstance.on("event:playbackStateChanged", (event) => {
      if (event.new_state === "stopped") {
        this.$store.dispatch("getCurrentTrack");
      }
      this.$store.commit("setPlaybackState", event.new_state);
    });

    mopidyInstance.on("event:trackPlaybackStarted", () => {
      this.$store.dispatch("getCurrentTrack");
      this.$store.commit('setVoted', false);
    });

    mopidyInstance.on("event:tracklistChanged", () => {
      this.$store.dispatch("getCurrentTrackList");
    });
  },
  components: {
    CurrentSong,
    Search,
    Playlist,
  },
});
