
























































import Mopidy from "mopidy";
import Vue from "vue";
import Multiselect from "vue-multiselect";
import { addToQueue, play, search } from "../../utils/mopidy";
import { OptionGroup, Source } from "@/types/types";
import { debounce } from "lodash";
import { mapGetters } from "vuex";

interface Data {
  queryString: string;
  selectedTrack?: Mopidy.models.Track;
  results: OptionGroup[];
  isLoading: boolean;
  youtubeEnabled: boolean;
  snackbar: boolean;
}

const HOUR_IN_MILLISECONDS = 3600000;

const getSource = (sourceUri: string) => {
  if (sourceUri?.includes("youtube")) {
    return Source.Youtube;
  } else if (sourceUri?.includes("spotify")) {
    return Source.Spotify;
  } else if (sourceUri?.includes("soundcloud")) {
    return Source.SoundCloud;
  } else if (sourceUri?.includes("bandcamp")) {
    return Source.SoundCloud;
  } else if (sourceUri?.includes("tidal")) {
    return Source.Tidal;
  } else {
    return Source.Tidal; //paska hacki haiska vittu tidal plugarin tekijäö ei tuu mukana 
  }
};

export default Vue.extend({
  name: "search",
  data: function (): Data {
    return {
      queryString: "",
      selectedTrack: undefined,
      results: [],
      isLoading: false,
      youtubeEnabled: false,
      snackbar: false,
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    ...mapGetters(["getPlaybackState"]),
  },
  methods: {
    async addToQueue(event: Mopidy.models.Track): Promise<void> {
      this.selectedTrack = undefined;
      await addToQueue(event);
      if (this.getPlaybackState === "stopped") {
        await play();
      }
      this.snackbar = true;
    },
    combineSearch(query: string) {
      console.log(query);
      if(typeof query === 'string') {
        this.queryString = query;
      } else {
        this.queryString = "";
      }
      this.searchTracks(query);
    },
    searchTracks: debounce(async function (query: string): Promise<void> {
      if (query) {
        // @ts-ignore
        this.isLoading = true;
        // @ts-ignore
        const tracks = (await search(query, this.youtubeEnabled)) || [];
        const groupedTracks: OptionGroup[] = tracks.map((x) => ({
          source: getSource(x.uri),
          // filter over hour long tracks
          tracks: x.tracks
            ? x.tracks
                .filter((y) => y.length < HOUR_IN_MILLISECONDS)
                .splice(0, 10)
            : [],
        }));
        // @ts-ignore
        this.results = groupedTracks;
        // @ts-ignore
        this.isLoading = false;
      } else {
        // @ts-ignore
        this.isLoading = false;
      }
    }, 300),
  },
});
