import Mopidy from "mopidy";

export enum Source {
  Youtube = "Youtube",
  Spotify = "Spotify",
  SoundCloud = "SoundCloud",
  Unknown = "Source unknown",
  Tidal = "Tidal"
}

export interface OptionGroup {
  source: Source;
  tracks: Mopidy.models.Track[];
}
