import Mopidy from "mopidy";
import Vue from "vue";
import Vuex from "vuex";
import { State } from "../types/vuex";
import { mopidyInstance } from "../utils/mopidy";

Vue.use(Vuex);

export default new Vuex.Store<State>({
  state: {
    playlist: [],
    playbackState: "stopped",
    currentTrack: undefined,
    images: {},
    voted: false,
    volume: 50,
  },
  mutations: {
    setTracklist: (state, payload: Mopidy.models.TlTrack[]) => {
      state.playlist = payload;
    },
    setPlaybackState: (state: State, payload: Mopidy.core.PlaybackState) => {
      state.playbackState = payload;
    },
    setCurrentTrack: (state: State, payload: Mopidy.models.Track) => {
      state.currentTrack = payload;
    },
    setImages: (
      state: State,
      payload: { [uri: string]: Mopidy.models.Image }
    ) => {
      state.images = { ...state.images, ...payload };
    },
    setVoted: (state: State, payload: boolean) => {
      state.voted = payload;
    },
    setVolume: (state: State, payload: number) => {
      state.volume = payload;
    }
  },
  getters: {
    getPlaylist: (state) => state.playlist,
    getCurrentSong: (state) => state.currentTrack,
    getPlaybackState: (state) => state.playbackState,
    getImage: (state) => (uri: string) => state.images[uri],
    getVoted: (state) => state.voted,
    getVolume: (state) => state.volume,
  },
  actions: {
    getCurrentTrackList: async ({ commit }) => {
      const tracks = await mopidyInstance.tracklist?.getTlTracks();
      commit("setTracklist", tracks);
    },
    getPlaybackState: async ({ commit }) => {
      const state = await mopidyInstance.playback?.getState();
      commit("setPlaybackState", state);
    },
    getCurrentTrack: async ({ commit }) => {
      const track = await mopidyInstance.playback?.getCurrentTrack();
      if (track?.uri) {
        const images = await mopidyInstance.library?.getImages({
          uris: [track?.uri],
        });
        commit("setImages", images);
      }
      commit("setCurrentTrack", track);
    },
    play: async () => {
      mopidyInstance.playback?.resume();
    },
    pause: async () => {
      mopidyInstance.playback?.pause();
    },
    setVolume: async ({commit}, payload: number) => {
      mopidyInstance.mixer?.setVolume({volume: payload})
      commit('setVolume', payload);
    }
  },
  modules: {},
});
