



























































































































































import Vue from "vue";
import { mapGetters, mapMutations } from "vuex";
import { pause, play } from "../../utils/mopidy";
import ProgressionBlock from "./ProgressionBlock.vue";
import { debounce } from "lodash";


interface Data {
  volume: number;
  snackbar: boolean;
}

export default Vue.extend({
  name: "CurrentSong",
  data: function (): Data {
    return {
      volume: 50,
      snackbar: false,
    };
  },
  components: {
    ProgressionBlock,
  },
  methods: {
    ...mapMutations(["setVoted"]),
    async pause(): Promise<void> {
      await pause();
    },
    async play(): Promise<void> {
      await play();
    },
    async voteSkip(): Promise<void> {
      try {
        const res = await fetch("/kansanradio/skip");
        if(res.status === 200) {
          this.snackbar = true;
          this.setVoted(true);
        }
      } catch (err) {
        console.log(err);
      }
    },
    setVolume: debounce(async function (value: number): Promise<void> {
      // @ts-ignore
      this.$store.dispatch('setVolume', value);
    }, 500),
  },
  computed: {
    ...mapGetters([
      "getCurrentSong",
      "getPlaybackState",
      "getImage",
      "getPlaylist",
      "getVoted",
      "getVolume"
    ]),
    getCurrentImage(): string {
      if (this.getCurrentSong.uri) {
        return this.getImage(this.getCurrentSong.uri)[0]?.uri;
      } else {
        return "";
      }
    },
    songAvailableAndPaused(): boolean {
      const paused =
        this.getPlaybackState === "stopped" ||
        this.getPlaybackState === "paused";
      const songAvailable = this.getPlaylist && this.getPlaylist.length > 0;
      return paused && songAvailable;
    },
    songPlaying(): boolean {
      return this.getPlaybackState === "playing";
    },
  },
});
